<template>
  <section class="section section-video">
    <div class="container">
      <div class="video-player" ref="videoBlock">
        <video
          ref="video"
          @ended="endVideoEvent"
          :poster="poster"
          @click="togglePlay"
          :autoPlay="false"
          :controls="false"
        ></video>

        <ShakaPlayer
          v-if="video"
          ref="player"
          :video="video"
          v-on:tracks="onGetTracks"
          v-on:subtitles="onGetSubtitles"
          v-on:stats="onStats"
          v-on:buffer="onBuffer"
          v-on:log="log"
          v-on:loaded="loaded"
          v-on:loading="loaded"
        />
        <a href="" class="start" v-if="!playing" @click.prevent="play"
          ><span><i></i></span
        ></a>
        <controls
          :video="this.$refs.video"
          :video-block="this.$refs.videoBlock"
          :is-advertise="isAdvertise"
          v-on:play="play"
          v-on:pause="pause"
          :tracks="tracks"
          :subtitles="subtitles"
          :data="data"
          :disabled-subtitles="disabledSubtitles"
          @changeTrack="onChangeTrack"
          @changeSubtitles="onChangeSubtitles"
          @disabledSubtitles="onDisabledSubtitles"
          @timeUpdate="timeUpdate"
          @playing="playing = $event"
          :stats="stats"
          :buffer="buffer"
        ></controls>
      </div>
    </div>
  </section>
</template>

<script>
import ShakaPlayer from "@/components/player/ShakaPlayer";
import Controls from "@/components/player/Controls";
import cover from "@/mixins/cover";
import { mapMutations, mapState } from "vuex";
import events from "@/mixins/events";

export default {
  name: "CheckDrmPlayer",
  components: {
    Controls,
    ShakaPlayer
  },
  mixins: [cover, events],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    advertiseData: {
      type: Object,
      default: () => {}
    },
    episodes: {
      type: Number,
      default: () => 0
    },
    test: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      logs: [],
      isAdvertise: false,
      initialPlay: true,
      playing: false,
      probe: null,
      video: null,
      tracks: [],
      subtitles: [],
      stats: {},
      buffer: "0",
      controlkey: 0,
      adRollsAndVideo: [],
      midRoll: [],
      midRollInterval: [],
      midRollPlayCount: 0,
      middleAd: [],
      disabledSubtitles: true,
      canSkip: 0
    };
  },
  computed: {
    ...mapState("player", {
      adShow: state => state.showAdIds
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    canSkipCheck() {
      if (this.canSkip) {
        return this.currentTimeWatch > this.canSkip;
      }
      return false;
    },
    poster() {
      if (!this.initialPlay) {
        return "";
      } else if (this.data.cover) {
        return this.replaceCoverImage(this.data.cover);
      }
      return "";
    }
  },
  created() {},
  watch: {
    video: function() {
      this.$nextTick(async () => {
        await this.init();
      });
    }
  },
  mounted() {
    this.video = this.$refs.video;
  },
  methods: {
    ...mapMutations("player", {
      setPlayerEntity: "ADD_ENTITY"
    }),
    ...mapMutations("settings", {
      setSettingsEntity: "SET_ENTITY"
    }),
    timeUpdate(event) {
      this.currentTimeWatch = event;
    },
    skipAd() {},
    endVideoEvent() {
      if (this.adRollsAndVideo[0].main) {
        let splice = this.adRollsAndVideo.splice(0, 1);
        if (splice[0].uid && !this.test) {
          this.setPlayerEntity(["showAdIds", splice[0]]);
        }
      }
      this.$emit("endplaylist", true);
    },
    async init() {
      this.log("[player] - init");
      let url = "stream";

      try {
        this.adRollsAndVideo.push({
          url: this.data.contentItems[this.episodes][url],
          startTime: 0,
          isDrm: this.data.isDrm,
          main: true
        });
      } catch (e) {
        this.log(["Empty Main Data "]);
      }
    },
    probeData() {
      this.log("[player] - probeSupport");
    },
    unload() {
      this.$refs.player.unload();
    },
    play() {
      const { video } = this.$refs.player;
      video.controls = false;
      if (!this.test) {
        video.style.setProperty("height", video.offsetHeight + "px");
      }
      if (this.initialPlay) {
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
        this.initialPlay = false;
      }
      video.play();
    },
    pause() {
      const { video } = this.$refs.player;
      video.pause();
    },
    togglePlay() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },
    async loaded() {
      if (!this.adRollsAndVideo[0].main) {
        this.isAdvertise = true;
      } else {
        this.isAdvertise = false;
      }
      const { video } = this.$refs.player;

      try {
        if (!this.initialPlay) {
          await video.play();
        }
        if (this.test) {
          await video.play();
        }
        this._drmActivationEvent("Success");
      } catch (e) {
        this._drmActivationEvent("Error");
      }
    },

    // Common player events.
    onGetTracks(event) {
      this.log("[player] - onGetTracks");
      this.tracks = event;
    },
    onChangeTrack(event) {
      this.log("[player] - onChangeTrack", event);
      this.tracks.forEach(item => {
        item.active = false;
      });
      event.active = true;
      this.$refs.player.selectTrack(event);
    },
    onGetSubtitles(event) {
      this.log("[player] - onGetSubtitles");
      this.subtitles = event;
    },
    onChangeSubtitles(event) {
      this.log("[player] - onChangeSubtitles", event);
      this.subtitles.forEach(item => {
        item.active = false;
      });
      event.active = true;
      this.disabledSubtitles = false;
      this.$refs.player.selectSubtitles(event);
    },
    onDisabledSubtitles(event) {
      this.subtitles.forEach(item => {
        item.active = false;
      });
      this.disabledSubtitles = true;
      this.$refs.player.disabledSubtitles(event);
    },
    onEnableAdaptation(event) {
      this.log("[player] - onEnableAdaptation", event);
      this.$refs.player.enableAdaptation(event);
    },
    onStats(event) {
      // this.log('[player] - onStats');
      this.stats = event;
    },
    onBuffer(event) {
      // this.log('[player] - onBuffer');
      this.buffer = event;
    },

    // Logger.
    log(...message) {
      if (message.length > 1) {
        if (message[1].category === 6 && message[1].code === 6001) {
          this.setSettingsEntity(["enabledDrm", false]);
          this._drmActivationEvent("Error");
        } else {
          console.log(message[1]);
          //this._drmActivationEvent("Success");
        }
      } // eslint-disable-line no-console
      // logs gets updated and passed to <Log /> prop.
      this.logs = this.logs.concat(message.join(" "));
    }
  }
};
</script>

<style scoped>
video {
  object-fit: inherit;
}
.controls-bar {
  display: none !important;
}
.video-player::-webkit-media-controls {
  display: none !important;
}
.player {
  text-align: left;
}

.player .video-container {
  /* height: 480px; */
  margin: 0 auto;
}

.player video {
  background: #aaa;
  height: 100%;
  width: 100%;
}

.player button {
  margin: 0 2px;
}

.player-buttons {
  display: inline-block;
  margin-bottom: 10px;
}

.player-buttons span {
  margin-left: 10px;
}
:-moz-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:-webkit-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}
:-webkit-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:-ms-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:fullscreen #controls {
  color: white;
  position: absolute;
}

:-moz-full-screen video {
  width: 100%;
  height: 100%;
}

:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

:-ms-full-screen video {
  width: 100%;
  height: 100%;
}

:fullscreen video {
  width: 100%;
  height: 100%;
}
video::-webkit-media-controls {
  opacity: 0;
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
</style>
